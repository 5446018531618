<template>
  <v-hover v-slot="{ hover }">
    <v-row :class="hover ? 'green lighten-5' : ''" align="center">
      <v-col cols="2">
        <b>{{ fito.dettagli.prodotto }}</b>
      </v-col>
      <v-col cols="2">
        {{ fito.dettagli.sostanze_attive }}
      </v-col>
      <v-col cols="2">
        {{ fito.dettagli.stato_amministrativo }}
      </v-col>
      <v-col cols="2">
        {{ fito.dettagli.scadenza_autorizzazione }}
      </v-col>
      <v-col cols="1">
        <span :class="in_magazzino > 0 ? 'green--text' : 'red--text'"
          ><b>{{ in_magazzino.toFixed(1) }}</b></span
        >
        Kg/Lt.
      </v-col>
      <v-col cols="2">
        <v-btn
          icon
          v-if="hover"
          color="blue"
          small
          class="mr-2 my-n2"
          @click="dialog_movimenti = true"
          ><v-icon>mdi-swap-vertical</v-icon></v-btn
        >
        <v-dialog v-model="dialog_movimenti" width="1200">
          <v-card>
            <v-card-title class="grey--text text-subtitle-1"
              >Movimenti: {{ fito.dettagli.prodotto }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-select
                    :items="anni"
                    v-model="anno_rif"
                    outlined
                    label="Anno Riferimento"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col class="ml-4"><b>Carico</b> </v-col>
                  </v-row>
                  <v-divider class="my-2" />
                  <template v-for="carico in movimenti_carico">
                    <MovimentoCarico
                      :movimento_carico="carico"
                      :id_prodotto="fito.id_fitofarmaco"
                      :key="carico.id"
                    />
                    <v-divider :key="carico.id + '-div'" class="my-2" />
                  </template>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col class="ml-4"><b>Scarico</b> </v-col>
                  </v-row>
                  <v-divider class="my-2" />
                  <template v-for="scarico in movimenti_scarico">
                    <MovimentoScarico
                      :movimento_scarico="scarico"
                      :id_prodotto="fito.id_fitofarmaco"
                      :key="scarico.id"
                    />
                    <v-divider :key="scarico.id + '-div'" class="my-2" />
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="grey" @click="dialog_movimenti = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_carico" width="600">
          <v-card>
            <v-card-title class="grey--text text-subtitle-1">
              Carico Fitofarmaco
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="fornitori"
                    v-model="id_fornitore"
                    label="Fornitore"
                    item-value="id"
                    item-text="nome"
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-dialog
                    ref="dialog"
                    v-model="dialog_data"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Data di Carico"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" scrollable locale="IT">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialog_data = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="qta_carico"
                    outlined
                    label="Quantità Caricata"
                    suffix="Kg/lt."
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="grey" @click="dialog_carico = false"
                >Chiudi</v-btn
              >
              <v-btn
                outlined
                color="green"
                @click="aggiungi_movimemnto()"
                :disabled="!id_fornitore || !date || !qta_carico"
                :loading="loading_invio"
                >Carica</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          icon
          color="green"
          class="mr-2 my-n2"
          small
          v-if="hover"
          @click="dialog_carico = true"
          ><v-icon>mdi-arrow-up</v-icon></v-btn
        >
        <!-- Dialog Scarico -->

        <v-dialog v-model="dialog_scarico" width="600">
          <v-card>
            <v-card-title class="grey--text text-subtitle-1">
              Scarico Fitofarmaco
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-dialog
                    ref="dialog"
                    v-model="dialog_data"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="Data di Scarico"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" scrollable locale="IT">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialog_data = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="qta_scarico"
                    outlined
                    label="Quantità Scaricata"
                    suffix="Kg/lt."
                    type="number"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Motivo dello scarico"
                    v-model="motivo_scarico"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="grey" @click="dialog_scarico = false"
                >Chiudi</v-btn
              >
              <v-btn
                outlined
                color="green"
                @click="aggiungi_movimemnto_scarico()"
                :disabled="!motivo_scarico || !qta_scarico"
                :loading="loading_invio"
                >Scarica</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          icon
          color="purple"
          class="mr-2 my-n2"
          small
          v-if="hover"
          @click="dialog_scarico = true"
          ><v-icon>mdi-arrow-down</v-icon></v-btn
        >
        <v-menu v-model="menu_delete">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="red"
              class="mr-2 my-n2"
              small
              v-on="on"
              v-show="hover"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title> Sei Sicuro ? </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="grey" @click="menu_delete = false"
                >Annulla</v-btn
              >
              <v-btn outlined color="red" @click="elimina_fitofarmaco()"
                >Elimina</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-snackbar color="red" top v-model="no_delete"
        ><v-icon class="mr-2">mdi-alert</v-icon> Impossibile
        eliminare. Il prodotto è usato in un evento</v-snackbar
      >
    </v-row>
  </v-hover>
</template>

<script>
import fito_element_mixin from "../mixin/fitofarmaco_element_mixin";
import MovimentoCarico from "./fitofarmaco_movimento_carico_element.vue";
import MovimentoScarico from "./fitofarmaco_movimento_scarico_element.vue";
export default {
  components: {
    MovimentoCarico,
    MovimentoScarico,
  },
  mixins: [fito_element_mixin],
};
</script>