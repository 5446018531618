<template>
  <v-hover v-slot="{ hover }">
    <v-row :class="hover ? 'green lighten-5' : ''">
      <v-col cols="2">
        <b>{{ avv.nome }}</b>
      </v-col>
      <v-col cols="2">
        {{ avv.nome_sc }}
      </v-col>
      <v-col cols="2">
        {{ tipo }}
      </v-col>
      <v-col cols="2">
        <v-btn
          icon
          color="red"
          v-if="hover"
          class="my-n2"
          :loading="loading_delete"
          @click="delete_avv()"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </v-col>
      <v-snackbar color="red" top v-model="no_delete"
        ><v-icon class="mr-2">mdi-alert</v-icon> Impossibile eliminare.
        L'avversità è presente in un evento</v-snackbar
      >
    </v-row>
  </v-hover>
</template>

<script>
export default {
  props: {
    avv: Object,
  },
  data() {
    return {
      loading_delete: false,
      no_delete: false,
    };
  },
  computed: {
    tipo() {
      switch (this.avv.tipo) {
        case 0:
          return "Fisiopatia";
        case 1:
          return "Insetto";
        case 2:
          return "Fungo";
        case 3:
          return "Infestante";
        default:
          return "";
      }
    },
  },
  methods: {
    delete_avv() {
      this.loading_delete = true;
      this.$store
        .dispatch("remove_avversit_fito", { id_avv: this.avv.id })
        .then((res) => {
          this.loading_delete = false;
          if (res.res == "forbidden") {
            this.no_delete = true;
          }
        });
    },
  },
};
</script>