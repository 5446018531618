<template>
  <v-card min-height="600">
    <v-card-title class="grey--text text-subtitle-1">
      Quaderno di Campagna
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>EVENTI</v-tab>
        <!-- <v-tab>COLTURE</v-tab> -->
        <v-tab>ZONE</v-tab>
        <v-tab>FITOFARMACI</v-tab>
        <v-tab>AVVERSITà</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-4">
        <v-tab-item>
          <EventiTab/>
        </v-tab-item>
        <!-- <v-tab-item> Colture </v-tab-item> -->
        <v-tab-item> <zone-tab/></v-tab-item>
        <v-tab-item>
          <FitofarmaciTab></FitofarmaciTab>
        </v-tab-item>
        <v-tab-item> <AvversitTab /> </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import FitofarmaciTab from "./fitofarmaci_tab.vue";
import AvversitTab from "./avversita_tab.vue";
import ZoneTab from "./zone_tab.vue"
import EventiTab from "./eventi_tab.vue"
export default {
  components: {
    FitofarmaciTab,
    AvversitTab,
    ZoneTab,
    EventiTab
  },
  data() {
    return {
      tab: null,
      value: "",
    };
  },
};
</script>