import { render, staticRenderFns } from "./evento_list_element_printable.vue?vue&type=template&id=9dfb45c6&scoped=true&"
import script from "./evento_list_element_printable.vue?vue&type=script&lang=js&"
export * from "./evento_list_element_printable.vue?vue&type=script&lang=js&"
import style0 from "./evento_list_element_printable.vue?vue&type=style&index=0&id=9dfb45c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dfb45c6",
  null
  
)

export default component.exports