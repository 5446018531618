<template>
  <div>
    <v-fab-transition hide-on-leave>
      <v-btn
        fab
        fixed
        bottom
        right
        dark
        color="green"
        @click="$refs.nuovo_evento.open()"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-fab-transition>
    <NuovoEvento ref="nuovo_evento"></NuovoEvento>
    <v-select
      outlined
      label="Anno Riferimento"
      v-model="anno_rif"
      :items="anni"
      class="mt-4"
      hide-details
    ></v-select>
    <v-list>
      <EventoElement
        v-for="evento in eventi"
        :key="evento.id"
        :evento="evento"
      />
    </v-list>
  </div>
</template>

<script>
import eventi_tab_mixin from "../mixin/eventi_tab_mixin";
import EventoElement from "./evento_list_el_mobile.vue";
import NuovoEvento from "./evento_nuovo_dialog.vue";

export default {
  components: {
    EventoElement,
    NuovoEvento,
  },
  mixins: [eventi_tab_mixin],
};
</script>