<template>
  <v-row align="center">
    <v-col cols="1">
      {{ data_evento }}
    </v-col>
    <v-col cols="4">
      <v-row no-gutters v-for="fito in evento.fitofarmaci" :key="fito.id">
        <v-col cols="8">
          <span class="mr-2"
            ><b>{{
              fitofarmaco(fito.id_fitofarmaco).dettagli.prodotto
            }}</b></span
          >
          ({{ fitofarmaco(fito.id_fitofarmaco).dettagli.sostanze_attive }})
        </v-col>
        <v-col cols="4">
          <span
            ><b>{{ fito.qta_impiegata }}</b> Kg/Lt.
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2">
      <v-row
        no-gutters
        v-for="id_avversita in evento.id_avversit"
        :key="id_avversita"
      >
        <span class="mr-2"
          ><b>{{ avversita(id_avversita).nome }}</b></span
        >
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row no-gutters v-for="id_zona in evento.id_zone" :key="id_zona">
        <span class="mr-2"
          ><b>{{ zona(id_zona).nome }}</b></span
        >
        ({{ zona(id_zona).superficie }} m²)
      </v-row>
    </v-col>
    <v-col cols="2">
      {{ fase_fenol }}
    </v-col>
    <v-col cols="2">
      <v-dialog width="400" v-model="dialog_colture_trattate">
        <template v-slot:activator="{ on }">
          <v-btn icon color="blue" v-on="on"
            ><v-icon>mdi-open-in-new</v-icon></v-btn
          >
        </template>
        <v-card>
          <v-card-title class="grey--text text-subtitle-1"
            >Colture trattate</v-card-title
          >
          <v-card-text>
            <v-list>
              <ColturaElement
                v-for="coltura in evento.colture_trattate"
                :key="coltura.id"
                :coltura="coltura"
              />
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="grey" @click="dialog_colture_trattate = false"
              >Chiudi</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_elimina" width="400">
        <template v-slot:activator="{ on }">
          <v-btn icon color="red" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <v-card>
          <v-card-title class="red--text">Elimina Evento</v-card-title>
          <v-card-text>Sei Sicuro ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="grey" @click="dialog_elimina = false"
              >Annulla</v-btn
            >
            <v-btn
              outlined
              color="red"
              :loading="loading_elimina"
              @click="elimina_evento()"
              >Elimina</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// import moment from "moment";
import evento_list_mixin from "../mixin/evento_list_mixin"
import ColturaElement from "./coltura_evento_element.vue";
export default {
  components: {
    ColturaElement,
  },
  mixins:[evento_list_mixin],
  // props: {
  //   evento: Object,
  // },
  // data: () => ({
  //   dialog_elimina: false,
  //   loading_elimina: false,
  //   dialog_colture_trattate: false,
  // }),
  // computed: {
  //   data_evento() {
  //     return moment.unix(this.evento.ts).format("DD MMM YYYY");
  //   },
  //   fase_fenol() {
  //     switch (this.evento.fase_fenol) {
  //       case 0:
  //         return "Trapianto";
  //       case 1:
  //         return "Fase Vegetativa";
  //       case 2:
  //         return "Pre-Fioritura";
  //     }
  //     return "";
  //   },
  // },
  // methods: {
  //   fitofarmaco(id) {
  //     return this.$store.getters.get_fitofarmaco_by_id(id);
  //   },
  //   zona(id) {
  //     return this.$store.getters.get_zona_by_id(id);
  //   },
  //   avversita(id) {
  //     return this.$store.getters.get_avversita_by_id(id);
  //   },
  //   elimina_evento() {
  //     this.loading_elimina = true;
  //     this.$store
  //       .dispatch("elimina_evento", { id_evento: this.evento.id })
  //       .then(() => {
  //         this.loading_elimina = false;
  //         this.dialog_elimina = false;
  //       });
  //   },
  // },
};
</script>