<template>
  <v-list-item three-line>
    <v-list-item-avatar>
      <v-img :src="avatar_img"></v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ nome[0] }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ nome[1] }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="lotto">
        Lotto: <b>{{ lotto.codice }}</b>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Posizionato: <b>{{ data_posizionamento }}</b>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Trapiantato: <b>{{ data_trapianto }}</b>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        icon
        :color="coltura.coltura.esaurito ? 'red' : 'green'"
        @click="switch_esaurito()"
        ><v-icon>{{
          coltura.coltura.esaurito ? "mdi-cancel" : "mdi-check"
        }}</v-icon></v-btn
      >
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from "moment";
export default {
  props: {
    coltura: Object,
  },
  data() {
    return {};
  },
  computed: {
    prodotto() {
      return this.$store.getters.get_prodotto_by_id(
        this.coltura.coltura.id_prodotto
      );
    },
    avatar_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.coltura.coltura.id_prodotto
      );
    },
    nome() {
      return this.prodotto.nome.split(" in ");
    },
    data_posizionamento() {
      return moment
        .unix(this.coltura.coltura.ts_posizionamento)
        .format("DD MMMM YYYY");
    },
    data_trapianto() {
      return moment
        .unix(this.coltura.coltura.ts_trapianto)
        .format("DD MMMM YYYY");
    },
    lotto() {
      let lotto = this.$store.getters.get_lotto_by_id(this.coltura.lotto);
      if (lotto) {
        return lotto;
      }
      this.$store.dispatch("get_lotto_by_cod", this.coltura.lotto);
      return null;
    },
  },
  methods: {
    switch_esaurito() {
      this.$store
        .dispatch("cambia_stato_produzione_coltura", {
          id_lotto: this.coltura.lotto,
          id_coltura: this.coltura.coltura.id,
          esaurito: !this.coltura.coltura.esaurito,
        })
        .then(() => {
          this.$emit("cambia_stato");
        });
    },
  },
};
</script>
