export default {
  data() {
    return {
      dialog_nuovo: false,
      id_prodotto_sel: null,
      tab_loading: false,
      isLoading: false,
      loading_invio: false,
      items: [],
      search: null,
      avviso_exist: false,
    };
  },
  watch: {
    search(val) {
      console.log(val);
      // Items have already been loaded
      if (val.length < 3) {
        return;
      }
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      // Lazily load input items
      fetch(process.env.VUE_APP_API_ROOT + "/admin/fitofarmaco/" + this.search)
        .then((res) => res.clone().json())
        .then((res) => {
          this.items = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    fitof_select() {
      return this.items.find((fito) => fito._id.$oid == this.id_prodotto_sel);
    },
    fitofarmaci_magazzino() {
      return this.$store.getters.get_fitofarmaci.sort((a,b) => a.dettagli.prodotto.localeCompare(b.dettagli.prodotto));
    },
  },
  methods: {
    aggiungi_prodotto_fito() {
      this.loading_invio = true;
      this.$store
        .dispatch("add_prodotto_fito", this.id_prodotto_sel)
        .then((res) => {
          this.loading_invio = false;
          if (res == "exist") {
            console.log("Esiste già");
            this.avviso_exist = true;
          } else {
            this.dialog_nuovo = false;
          }
        });
    },
  },
};
