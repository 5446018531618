<template>
  <div>
    <v-fab-transition hide-on-leave>
      <v-btn
        fab
        fixed
        bottom
        right
        dark
        color="green"
        @click="$refs.nuovo_fito.open()"
        ><v-icon>mdi-medication</v-icon></v-btn
      >
    </v-fab-transition>
    <fito-nuovo ref="nuovo_fito"/>
    <template v-for="fito in fitofarmaci_magazzino">
      <FitoElement :fito="fito" :key="fito.id" />
    </template>
  </div>
</template>

<script>
import fito_mixin from "../mixin/fitofarmaco_tab_mixin";
import FitoElement from "./fitofarmaco_element.vue";
import FitoNuovo from "./fitofarmaci_nuovo_mobile.vue"
export default {
  components: {
    FitoElement,
    FitoNuovo
  },
  mixins: [fito_mixin],
};
</script>