<template>
  <v-hover v-slot="{ hover }">
    <v-row :class="hover ? 'green lighten-5' : ''">
      <v-col cols="2">
        <b>{{ zona.nome }}</b>
      </v-col>
      <v-col cols="2">
        {{ tipo }}
      </v-col>
      <v-col cols="2"> {{ zona.superficie }} m² </v-col>
      <v-col cols="2">
        <v-dialog v-model="dialog_colture" width="800">
          <template v-slot:activator="{ on }">
            <v-btn icon color="blue" v-on="on"
              ><v-icon>mdi-open-in-new</v-icon></v-btn
            >
          </template>
          <v-card :loading="loading_ricerca">
            <v-card-title class="grey--text text-subtitle-1">
              <span class="mr-2">Colture Presenti </span> <b>{{ zona.nome }}</b
              ><v-spacer />
              <v-switch
                inset
                v-model="mostra_solo_dispo"
                label="Mostra Solo Disponibili"
              ></v-switch
            ></v-card-title>
            <v-card-text>
              <template v-if="lista_filtrata.length">
                <ColturaElement
                  v-for="coltura in lista_filtrata"
                  :key="coltura.id"
                  :coltura="coltura"
                  @cambia_stato="cerca_colture()"
                />
              </template>
              <v-row v-else justify="center"> Nessuna Coltura Presente </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="grey" @click="dialog_colture = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-btn
          icon
          color="red"
          v-if="hover"
          class="my-n2"
          :loading="loading_delete"
          @click="delete_zona()"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
        <v-btn icon color="grey" v-if="hover" @click="move_zona('up')"
          ><v-icon>mdi-chevron-up</v-icon></v-btn>

        <v-btn icon color="grey" v-if="hover" @click="move_zona('down')"
        ><v-icon>mdi-chevron-down</v-icon></v-btn>

      </v-col>
      <v-snackbar color="red" top v-model="no_delete"
        ><v-icon class="mr-2">mdi-alert</v-icon> Impossibile eliminare. La zona
        contiene delle colture</v-snackbar
      >
    </v-row>
  </v-hover>
</template>

<script>
import axios from "axios";
import ColturaElement from "./zona_colture_element.vue";
export default {
  components: { ColturaElement },
  props: {
    zona: Object,
  },
  data() {
    return {
      loading_delete: false,
      dialog_colture: false,
      loading_ricerca: false,
      lista_colture: [],
      mostra_solo_dispo: true,
      no_delete: false,
    };
  },
  watch: {
    dialog_colture: function () {
      if (this.dialog_colture) {
        this.cerca_colture();
      }
    },
  },
  computed: {
    tipo() {
      switch (this.zona.tipo) {
        case 0:
          return "Serra con bancali";
        case 1:
          return "Serra con Pacciamatura";
        case 2:
          return "Ombraio";
        case 3:
          return "Pieno Campo";
        default:
          return "";
      }
    },
    lista_filtrata() {
      if (this.mostra_solo_dispo) {
        return this.lista_colture.filter((colt) => !colt.coltura.esaurito);
      } else {
        return this.lista_colture;
      }
    },
  },
  methods: {
    delete_zona() {
      this.loading_delete = true;
      this.$store
        .dispatch("remove_zona_coltivazione", { id_zona: this.zona.id })
        .then((res) => {
          console.log(res);
          this.loading_delete = false;
          if (res.res == "forbidden") {
            this.no_delete = true;
          }
        });
    },
    cerca_colture() {
      this.loading_ricerca = true;
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/lotti/zona_coltivazione/" +
        this.zona.id +
        "/colture";
      let auth = this.$store.getters.get_credential;
      axios
        .get(url, { auth: auth })
        .then((res) => {
          this.lista_colture = res.data;
          this.loading_ricerca = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    move_zona(way) {
      if (way == "up") {
        this.$store.dispatch("move_zona_up", this.zona.id);
      } else {
        this.$store.dispatch("move_zona_down", this.zona.id);
      }
    },
  },
};
</script>