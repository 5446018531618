<template>
  <v-hover v-slot="{ hover }">
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="blue">mdi-arrow-up</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ data_format }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <b>{{ movimento_carico.qta_carico }}</b> Kg/Lt.
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ fornitore.nome }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon color="red" v-if="hover" :loading="loading_elimina" @click="elimina_movimento()"><v-icon>mdi-delete</v-icon></v-btn>
      </v-list-item-action>
    </v-list-item>
    <!-- <v-row no-gutters>
    <v-col cols="12"> Fornitore: {{ fornitore.nome }} </v-col>
    <v-col cols="12"> data: {{ data_format }} </v-col>
    <v-col cols="12">
      Quantità Caricata: {{ movimento_carico.qta_carico }} Kg/Lt.
    </v-col>
  </v-row> -->
  </v-hover>
</template>

<script>
import moment from "moment";
export default {
  props: {
    movimento_carico: Object,
    id_prodotto: String
  },
  data() {
    return {
      loading_elimina: false,
    };
  },
  computed: {
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(
        this.movimento_carico.id_fornitore
      );
    },

    data_format() {
      return moment.unix(this.movimento_carico.ts).format("DD/MM/YYYY");
    },
  },
  methods: {
    elimina_movimento() {
      this.loading_elimina = true;
      this.$store.dispatch("delete_movimento_prodotto_fito", {
        id_prodotto: this.id_prodotto,
        id_carico: this.movimento_carico.id,
      });
    },
  },
};
</script>