<template>
  <div>
    <v-row justify="center" align="center" v-if="loading">
      <v-progress-circular indeterminate width="2" color="green" />
    </v-row>
    <template v-else>
      <QuadernoCampagnaDesktop v-if="!$vuetify.breakpoint.mobile" />
      <QuadernoCampagnaMobile v-else />
    </template>
  </div>
</template>

<script>
import QuadernoCampagnaDesktop from "@/components/QuadernoCampagna/desktop/quaderno_campagna_main_desktop";
import QuadernoCampagnaMobile from "@/components/QuadernoCampagna/mobile/quaderno_campagna_main_mobile";
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    QuadernoCampagnaDesktop,
    QuadernoCampagnaMobile,
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.$store.dispatch("fetch_fitofarmaci"),
      this.$store.dispatch("fetch_avversita_fito"),
      this.$store.dispatch("fetch_eventi_qdc", {
        ts_inizio: moment().startOf("year").format("X"),
        ts_fine: moment().endOf("year").format("X"),
      }),
    ]).then(() => {
      this.loading = false;
    });
  },
};
</script>