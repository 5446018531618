<template>
  <v-list-item three-line>
    <v-list-item-content>
      <v-list-item-title>
        {{ data_evento }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ evento.id_zone.length }} Zone Trattate
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ evento.fitofarmaci.length }} Prodotti Usati
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-dialog v-model="dialog_elimina" width="400">
        <template v-slot:activator="{ on }">
          <v-btn icon color="red" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <v-card>
          <v-card-title class="red--text">Elimina Evento</v-card-title>
          <v-card-text>Sei Sicuro ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="grey" @click="dialog_elimina = false"
              >Annulla</v-btn
            >
            <v-btn
              outlined
              color="red"
              :loading="loading_elimina"
              @click="elimina_evento()"
              >Elimina</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
    <v-list-item-action>
      <v-btn icon color="blue" @click="$refs.dettaglio.open()"
        ><v-icon>mdi-open-in-new</v-icon></v-btn
      >

      <evento-dettaglio ref="dettaglio" :evento="evento" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import evento_list_mixin from "../mixin/evento_list_mixin";
import EventoDettaglio from "./evento_dettaglio_dialog.vue";

export default {
  components: {
    EventoDettaglio,
  },
  mixins: [evento_list_mixin],
};
</script>