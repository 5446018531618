<template>
  <v-row no-gutters>
    <v-card-title class="grey--text text-subtitle-1"
      >Zone di Coltivazione
      <v-spacer />
      <v-dialog v-model="dialog_nuovo" width="800">
        <template v-slot:activator="{ on }">
          <v-btn outlined color="green" v-on="on" class="ml-8"
            ><v-icon class="mr-2">mdi-greenhouse</v-icon>Aggiungi Zona</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="grey--text text-subtitle-1">
            Nuova Zona
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Nome"
                  v-model="nome"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Superficie"
                  v-model="superficie"
                  hide-details
                  suffix="m²"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="tipologia"
                  v-model="cod_tipo"
                  item-text="text"
                  item-value="cod"
                  hide-details
                  outlined
                  label="Tipologia"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey" text @click="dialog_nuovo = false"
              >chiudi</v-btn
            >
            <v-btn
              color="green"
              outlined
              :loading="loading_invia"
              :disabled="!nome || cod_tipo == null"
              @click="invia_nuovo()"
              >Invia</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2"> Nome Zona </v-col>
        <v-col cols="2"> Tipologia </v-col>
        <v-col cols="2"> Superficie </v-col>
      </v-row>
      <v-divider class="my-2" />
      <ZonaEl v-for="zona in zone" :key="zona.id" :zona="zona" />
    </v-card-text>
  </v-row>
</template>

<script>
import ZonaEl from "./zona_element.vue";
export default {
  components: {
    ZonaEl,
  },
  data() {
    return {
      dialog_nuovo: false,
      tipologia: [
        { cod: 0, text: "Serra con Bancali" },
        { cod: 1, text: "Serra con Pacciamatura" },
        { cod: 2, text: "Ombraio" },
        { cod: 3, text: "Pieno Campo" },
      ],
      loading_invia: null,
      nome: null,
      superficie: 0,
      cod_tipo: 0,
    };
  },
  computed: {
    zone() {
      return this.$store.getters.get_zone_coltivazione;
    },
  },
  methods: {
    invia_nuovo() {
      this.loading_invia = true;
      this.$store
        .dispatch("add_zona_coltivazione", {
          nome: this.nome,
          tipo: this.cod_tipo,
          superficie: this.superficie,
        })
        .then(() => {
          this.loading_invia = false;
          this.nome = "";
          this.estensione = 0;
          this.dialog_nuovo = false;
        });
    },
  },
};
</script>