<template>
  <v-list-item>
    <v-list-item-avatar>
        <v-img :src="avatar_img"></v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ prodotto.nome.split(" in ")[0] }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ prodotto.nome.split(" in ")[1] }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="lotto">
        lotto: <b>{{ lotto.codice }}</b> ({{fornitore.nome}})
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    coltura: Object,
  },
  data() {
    return {
      lotto: null,
    };
  },
  mounted() {
    this.$store
      .dispatch("ricerca_lotto", this.coltura.lotto_coltura)
      .then((res) => {
        console.log(res);
        this.lotto = res;
      })
      .catch(() => {});
  },
  computed: {
    prodotto() {
      return this.$store.getters.get_prodotto_raw_by_id(
        this.coltura.id_coltura
      );
    },
    fornitore(){
        return this.$store.getters.get_fornitore_by_id(this.lotto.id_fornitore)
    },
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.coltura.id_coltura;
    },
  },
};
</script>