<template>
  <v-dialog fullscreen v-model="dialog">
    <v-card>
      <v-snackbar v-model="avviso_exist" top shaped color="red">
        <v-icon class="mr-2">mdi-alert</v-icon>
        Attenzione. Il fitofarmaco selezionato è già presente
      </v-snackbar>
      <v-card-title class="grey--text text-subtitle-1"
        >Nuovo Fitofarmaco</v-card-title
      >
      <v-card-text>
        <v-autocomplete
          v-model="id_prodotto_sel"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          chips
          clearable
          hide-details
          hide-selected
          item-text="prodotto"
          item-value="_id.$oid"
          label="Ricerca Prodotto..."
          outlined
        ></v-autocomplete>
        <template v-if="fitof_select">
          <v-row class="mt-4">
            <v-col>
              <b># Registrazione: </b>
              {{ fitof_select.numero_registrazione }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Nome: </b>
              {{ fitof_select.prodotto }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Titolare Registrazione: </b>
              {{ fitof_select.impresa }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Sostanze: </b>
              {{ fitof_select.sostanze_attive }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Attività: </b>
              {{ fitof_select.attivita }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Formulazione: </b>
              {{ fitof_select.descrizione_formulazione }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Indicazioni di Pericolo: </b>
              {{ fitof_select.indicazioni_di_pericolo }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Data Registrazione: </b>
              {{ fitof_select.data_registrazione }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Stato Amministrativo: </b>
              {{ fitof_select.stato_amministrativo }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Scadenza Autorizzazione: </b>
              {{ fitof_select.scadenza_autorizzazione }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Decorrenza Revoca: </b>
              {{ fitof_select.data_decorrenza_revoca }}
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
        <v-btn
          outlined
          color="green"
          :disabled="!fitof_select"
          :loading="loading_invio"
          @click="aggiungi_prodotto_fito"
          >Aggiungi</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fito_tab_mixin from "../mixin/fitofarmaco_tab_mixin";
export default {
  mixins: [fito_tab_mixin],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#nuovo-fito" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#nuovo-fito");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
</script>