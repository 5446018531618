import moment from "moment";

export default {
  props: {
    evento: Object,
  },
  data: () => ({
    dialog_elimina: false,
    loading_elimina: false,
    dialog_colture_trattate: false,
  }),
  computed: {
    data_evento() {
      return moment.unix(this.evento.ts).format("DD MMM YYYY");
    },
    fase_fenol() {
      switch (this.evento.fase_fenol) {
        case 0:
          return "Trapianto";
        case 1:
          return "Fase Vegetativa";
        case 2:
          return "Pre-Fioritura";
      }
      return "";
    },
  },
  methods: {
    fitofarmaco(id) {
      return this.$store.getters.get_fitofarmaco_by_id(id);
    },
    zona(id) {
      return this.$store.getters.get_zona_by_id(id);
    },
    tipo_zona(tipo) {
      switch (tipo) {
        case 0:
          return "Serra con Bancali";
        case 1:
          return "Serra con Telo";
        case 2:
          return "Ombraio";
        case 3:
          return "Pieno Campo";
      }
    },
    avversita(id) {
      return this.$store.getters.get_avversita_by_id(id);
    },
    elimina_evento() {
      this.loading_elimina = true;
      this.$store
        .dispatch("elimina_evento", { id_evento: this.evento.id })
        .then(() => {
          this.loading_elimina = false;
          this.dialog_elimina = false;
        });
    },
  },
};
