<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ fito.dettagli.prodotto }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ fito.dettagli.sostanze_attive }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text>
      <span
        :class="in_magazzino > 0 ? 'green--text' : 'red--text'"
        style="font-size: 16px"
        ><b>{{ in_magazzino.toFixed(1) }}</b></span
      >
      Kg/Lt.
    </v-list-item-action-text>
    <v-list-item-action>
      <v-btn icon color="blue" @click="$refs.carico_scarico.open()"
        ><v-icon>mdi-swap-vertical</v-icon></v-btn
      >
      <FitoCaricoScarico ref="carico_scarico" :fito="fito" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import FitoCaricoScarico from "./fitofarmaco_carico_scarico.vue";
import fito_element_mixin from "../mixin/fitofarmaco_element_mixin";
export default {
  components: {
    FitoCaricoScarico,
  },
  mixins: [fito_element_mixin],
};
</script>