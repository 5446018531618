<template>
  <tr>
    <td>
      {{ data_evento }}
    </td>
    <td>
      <template v-for="fito in evento.fitofarmaci">
        <span :key="fito.id" style="padding-right: 30px">
          {{ fitofarmaco(fito.id_fitofarmaco).dettagli.prodotto }} </span
        ><b :key="fito.id + 'b'">{{ fito.qta_impiegata }}</b> Kg/Lt.
        <br :key="fito.id + 'br'" />
      </template>
    </td>
    <td>
      <template v-for="id_avversita in evento.id_avversit">
        <span class="mr-2" :key="id_avversita"
          ><b>{{ avversita(id_avversita).nome }}</b></span
        >
        <br :key="id_avversita + 'br'"/>
      </template>
    </td>
    <td>
      <template v-for="id_zona in evento.id_zone">
        <span class="mr-2" :key="id_zona"
          ><b>{{ zona(id_zona).nome }}</b></span
        >
        ({{ zona(id_zona).superficie }} m²)
        <br :key="id_zona.id + 'br'" />
      </template>
    </td>
    <td>
      {{ fase_fenol_ridotta }}
    </td>
    <td>
      {{evento.note}}
    </td>
  </tr>
  <!-- <v-row align="center">
    <v-col cols="2"> </v-col>
    <v-col cols="3">
      <v-row no-gutters>
        <v-col cols="8">
          <span class="mr-2"></span>
        </v-col>
        <v-col cols="4">
          <span> </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2">
      <v-row
        no-gutters
        v-for="id_avversita in evento.id_avversit"
        :key="id_avversita"
      >
        <span class="mr-2"
          ><b>{{ avversita(id_avversita).nome }}</b></span
        >
      </v-row>
    </v-col>
    <v-col cols="3">
      <v-row no-gutters v-for="id_zona in evento.id_zone" :key="id_zona">
        <span class="mr-2"
          ><b>{{ zona(id_zona).nome }}</b></span
        >
        ({{ zona(id_zona).superficie }} m²)
      </v-row>
    </v-col>
    <v-col cols="1">
      {{ fase_fenol_ridotta }}
    </v-col>
  </v-row> -->
</template>

<script>
import evento_list_mixin from "../mixin/evento_list_mixin";
// import ColturaElement from "./coltura_evento_element.vue";
export default {
  components: {
    // ColturaElement,
  },
  mixins: [evento_list_mixin],
  computed: {
    fase_fenol_ridotta() {
      switch (this.evento.fase_fenol) {
        case 0:
          return "T";
        case 1:
          return "FV";
        case 2:
          return "IF";
      }
      return "";
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
}
</style>