<template>
    <v-tabs color="green" show-arrows="">
        <v-tab>Eventi</v-tab>
        <v-tab>Fitofarmaci</v-tab>
        <v-tab>Avversità</v-tab>
        <v-tab>Zone</v-tab>

        <v-tab-item>
            <eventi-tab/>
        </v-tab-item>
        <v-tab-item>
            <fito-tab/>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import EventiTab from "./eventi_tab_mobile.vue"
import FitoTab from "./fitofarmaci_tab_mobile.vue"
export default {
    components:{
        EventiTab,
        FitoTab
    }
}
</script>