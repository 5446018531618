import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      date: moment().format("YYYY-MM-DD"),
      dialog_data: false,
      id_zone: [],
      lista_colture: [],
      lista_avversita: [],
      fitofarmaci_utiliz: [],
      qta_fitofarmaci: [],
      tab: 0,
      colture_zone: [],
      prodotti_list: [],
      check_all: [],
      fase_fenologica: 0,
      lista_fasi_fenologiche: [
        { cod: 0, text: "Trapianto" },
        { cod: 1, text: "Fase Vegetativa" },
        { cod: 2, text: "Pre-Fioritura" },
      ],
      loading_nuovo: false,
      note: "",
    };
  },
  watch: {
    id_zone: function () {
      this.prodotti_list = [];
      this.check_all = [];
      let array_filtro = this.colture_zone.filter((item) =>
        this.id_zone.includes(item.id)
      );
      this.colture_zone = array_filtro;
      this.id_zone.forEach((id_zona) => {
        this.ricerca_colture(id_zona);
      });
    },
  },
  computed: {
    zone() {
      return this.$store.getters.get_zone_coltivazione;
    },
    fitofarmaci() {
      return this.$store.getters.get_fitofarmaci;
    },
    avversita() {
      return this.$store.getters.get_avversita;
    },
    num_box_ok() {
      return this.qta_fitofarmaci.every((qta) => !isNaN(qta) && qta != '');
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    ricerca_colture(id_zona) {
      if (this.colture_zone.some((zona) => zona.id == id_zona)) {
        return;
      }
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/lotti/zona_coltivazione/" +
        id_zona +
        "/colture";
      let auth = this.$store.getters.get_credential;
      axios.get(url, { auth: auth }).then((res) => {
        console.log(res.data);
        let colture_filtrate = res.data.filter(
          (colt) => !colt.coltura.esaurito
        );
        this.colture_zone.push({
          id: id_zona,
          colture: colture_filtrate,
        });
      });
    },
    nome_coltura(id) {
      return this.$store.getters.get_prodotto_by_id(id).nome.split("in");
    },
    fitofarmaco(id) {
      return this.$store.getters.get_fitofarmaco_by_id(id);
    },
    zona(id) {
      return this.$store.getters.get_zona_by_id(id);
    },
    lotto(id) {
      let lotto = this.$store.getters.get_lotto_by_id(id);
      if (lotto) {
        return lotto;
      }
      this.$store.dispatch("get_lotto_by_id", id);
      return null;
    },
    avatar_img(id) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
    },
    fill_all(index) {
      if (this.check_all[index]) {
        this.colture_zone[index].colture.forEach((coltura) => {
          let presente = this.prodotti_list.some(
            (prodotto) =>
              prodotto.id == coltura.coltura.id_prodotto &&
              prodotto.lotto == coltura.lotto
          );
          console.log(presente);
          if (!presente) {
            this.prodotti_list.push({
              id: coltura.coltura.id_prodotto,
              lotto: coltura.lotto,
            });
          } else {
            console.log("gia presente");
          }
        });
      } else {
        this.colture_zone[index].colture.forEach((coltura) => {
          let index = this.prodotti_list.findIndex(
            (prodotto) =>
              prodotto.id == coltura.coltura.id &&
              prodotto.lotto == coltura.lotto
          );
          if (index > -1) {
            this.prodotti_list.splice(index, 1);
          }
        });
      }
    },
    toggle_item(payload) {
      console.log(this.prodotti_list);
      let index = this.prodotti_list.findIndex(
        (item) => item.id == payload.id && item.lotto == payload.lotto
      );
      if (index < 0) {
        this.prodotti_list.push({
          id: payload.id,
          lotto: payload.lotto,
        });
      } else {
        this.prodotti_list.splice(index, 1);
      }
    },
    item_selected(payload) {
      return this.prodotti_list.some(
        (item) => item.id == payload.id && item.lotto == payload.lotto
      );
    },
    invia_evento() {
      let array_fito = this.fitofarmaci_utiliz.map((id_fito, index) => {
        return {
          id_fito: id_fito,
          qta: this.qta_fitofarmaci[index],
        };
      });
      const arrayUnione = [].concat(...this.prodotti_list);
      const chiaveComposta = (oggetto) => `${oggetto.id}_${oggetto.lotto}`;
      const arrayPulito = Array.from(
        new Set(arrayUnione.map(chiaveComposta))
      ).map((chiave) => {
        const [id, lotto] = chiave.split("_");
        return arrayUnione.find(
          (oggetto) => oggetto.id === id && oggetto.lotto === lotto
        );
      });
      this.loading_nuovo = true;
      this.$store
        .dispatch("salva_nuovo_evento", {
          ts: moment(this.date).format("X"),
          fitofarmaci: array_fito,
          avversita: this.lista_avversita,
          zone: this.id_zone,
          colture: arrayPulito,
          fase_fenologica: this.fase_fenologica,
          note: this.note,
        })
        .then(() => {
          this.loading_nuovo = false;
          this.dialog = false;
        });
    },
  },
};
