<template>
  <v-card flat>
    <v-card-title class="grey--text text-subtitle-1">
      Eventi Registrati
      <v-btn
        outlined
        class="ml-6"
        color="green"
        @click="$refs.nuovo_evento.open()"
        ><v-icon class="mr-2">mdi-plus</v-icon>Aggiungi Evento</v-btn
      >
      <NuovoEvento ref="nuovo_evento" />
      <v-spacer> </v-spacer>
      <v-btn icon color="purple" @click="view_mode = 1"
        ><v-icon>mdi-calendar</v-icon></v-btn
      >
      <v-btn icon color="blue" @click="view_mode = 0"
        ><v-icon>mdi-format-list-bulleted-square</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-divider class="mb-4" />
      <v-row>
        <v-col cols="2">
          <v-select
            label="Anno di Riferimento"
            outlined
            :items="anni"
            v-model="anno_rif"
            hide-details
          />
        </v-col>
        <v-col cols="3">
          <v-dialog v-model="print_dialog" width="1200">
            <template v-slot:activator="{ on }">
              <v-btn outlined color="purple" v-on="on" x-large><v-icon class="mr-2">mdi-printer</v-icon> Stampa</v-btn>
            </template>
            <v-card flat>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  class="printable-card"
                  text
                  color="grey"
                  @click="print_dialog = false"
                  >chiudi</v-btn
                >
                <v-btn
                  class="printable-card"
                  outlined
                  color="purple"
                  @click="createPDF()"
                  >
                  <v-icon class="mr-2">mdi-printer</v-icon>
                  stampa</v-btn
                >
                
              </v-card-actions>
              <div id="contentToPrint">
                <v-card-title>Trattamenti Annata {{ anno_rif }}</v-card-title>
                <v-card-text>
                  <table>
                    <tr>
                      <th style="border: 1px solid #dddddd">Data</th>
                      <th>Prodotti</th>
                      <th>Avversità</th>
                      <th>Zone</th>
                      <th>Fase Fen.</th>
                      <th>Note</th>
                    </tr>
                    <EventoPrintable
                      v-for="evento in eventi"
                      :key="evento.id"
                      :evento="evento"
                    />
                  </table>
                </v-card-text>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <template v-if="view_mode == 0">
        <v-row>
          <v-col cols="1"> Data </v-col>
          <v-col cols="4"> Prodotti Utilizzati </v-col>
          <v-col cols="2">Avversità</v-col>
          <v-col cols="1"> Zone Trattate </v-col>
          <v-col cols="2"> Fase Fenologica</v-col>
          <v-col cols="2"> Colture</v-col>
        </v-row>
        <v-divider class="my-4" />
        <template v-for="evento in eventi">
          <Evento :key="evento.id" :evento="evento" />
          <v-divider class="my-2" :key="evento.id + '-div'" />
        </template>
      </template>
      <template v-else>
        <v-toolbar tile elevation="0">
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
        <v-calendar
          ref="calendar"
          v-model="value"
          type="month"
          :events="events"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @change="getEvents"
        ></v-calendar>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
// import moment from "moment";
import NuovoEvento from "./nuovo_evento_dialog.vue";
import Evento from "./evento_list_element.vue";
import EventoPrintable from "./evento_list_element_printable.vue";
import eventi_tab_mixin from "../mixin/eventi_tab_mixin";
import jsPDF from "jspdf";
export default {
  components: {
    NuovoEvento,
    Evento,
    EventoPrintable,
  },
  data() {
    return {
      print_dialog: false,
    };
  },
  mixins: [eventi_tab_mixin],
  methods: {
    print_card() {
      // Stampa solo il contenuto della card
      window.print();
    },
    createPDF() {
      var elementHTML = document.querySelector("#contentToPrint");
      const pdf = new jsPDF({ orientation: "landscape" });
      pdf.html(elementHTML, {
        callback: function (doc) {
          // Save the PDF
          doc.save("document-html.pdf");
        },
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        width: 240, //target width in the PDF document
        windowWidth: 1100, //window width in CSS pixels
      });
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
}
</style>