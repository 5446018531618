<template>
  <v-dialog fullscreen v-model="dialog">
    <v-card>
      <v-card-title class="grey--text text-subtitle-1"
        >Dettaglio Evento</v-card-title
      >
      <v-card-text>
        <v-divider class="mb-4" />
        <v-row>
          <v-col>
            <v-icon class="mr-2">mdi-calendar</v-icon>Data Evento:
            <b>{{ data_evento }}</b></v-col
          >
        </v-row>
        <v-divider class="my-4" />

        <v-row>
          <v-col>
            <v-icon class="mr-2">mdi-leaf</v-icon> Fase Fenologica:
            <b> {{ fase_fenol }}</b>
          </v-col>
        </v-row>
        <v-divider class="my-4" />

        <v-row>
          <v-col
            ><v-icon class="mr-2">mdi-medication</v-icon> Prodotti Utilizzati:
          </v-col>
        </v-row>
        <v-list-item v-for="fito in evento.fitofarmaci" :key="fito.id" two-line>
          <v-list-item-content>
            <v-list-item-title>
              {{ fitofarmaco(fito.id_fitofarmaco).dettagli.prodotto }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ fitofarmaco(fito.id_fitofarmaco).dettagli.sostanze_attive }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action-text>
            <span style="font-size: 18px"
              ><b>{{ fito.qta_impiegata }}</b></span
            >
            Kg/Lt.
          </v-list-item-action-text>
        </v-list-item>
        <v-divider class="mb-4" />

        <v-row>
          <v-col><v-icon class="mr-2">mdi-bug</v-icon> Avversità: </v-col>
        </v-row>
        <v-list-item
          v-for="avversit in evento.id_avversit"
          :key="avversit"
          two-line
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ avversita(avversit).nome }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ avversita(avversit).nome_sc }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-4" />

        <v-row>
          <v-col
            ><v-icon class="mr-2">mdi-greenhouse</v-icon> Zone Trattate:
          </v-col>
        </v-row>
        <v-list-item v-for="id_zona in evento.id_zone" :key="id_zona" two-line>
          <v-list-item-content>
            <v-list-item-title>
              {{ zona(id_zona).nome }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ tipo_zona(zona(id_zona).tipo) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-4" />

        <v-row>
          <v-col
            ><v-icon class="mr-2">mdi-flower</v-icon> Colture Trattate:
          </v-col>
        </v-row>
        <coltura-element
          v-for="coltura in evento.colture_trattate"
          :key="coltura.id"
          :coltura="coltura"
        ></coltura-element>
        <v-divider class="mb-4" />
        <v-row>
          <v-col
            ><v-icon class="mr-2">mdi-text-box</v-icon> Note:
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            {{evento.note}}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import evento_mixin from "../mixin/evento_list_mixin";
import ColturaElement from "../desktop/coltura_evento_element.vue";
export default {
  components: {
    ColturaElement,
  },
  mixins: [evento_mixin],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-evento" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-evento");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
</script>