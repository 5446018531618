import moment from "moment";

export default {
  data: () => ({
    view_mode: 0,
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    anno_rif: moment().format("YYYY"),
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
  }),
  watch: {
    anno_rif: function () {
      this.$store.dispatch("fetch_eventi_qdc", {
        ts_inizio: moment(`${this.anno_rif}-01-01 00:00:00`).unix(),
        ts_fine: moment(`${this.anno_rif}-12-31 23:59:59`).unix(),
      });
    },
  },
  computed: {
    eventi() {
      return this.$store.getters.get_eventi_qdc;
    },
    anni() {
      let adesso = moment().format("YYYY");
      return [adesso, adesso - 1, adesso - 2];
    },
  },
  methods: {
    getEvents() {
      this.events = this.eventi.map((ev) => {
        return {
          name: this.get_fitofarmaco(ev.fitofarmaci[0].id_fitofarmaco).dettagli
            .prodotto,
          start: moment.unix(ev.ts).format("YYYY-MM-DD"),
          color: "purple",
        };
      });
    },
    getEventColor(event) {
      return event.color;
    },
    get_fitofarmaco(id) {
      return this.$store.getters.get_fitofarmaco_by_id(id);
    },
  },
};
