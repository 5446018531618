<template>
  <v-row no-gutters>
    <!-- <v-card width="100"> -->
    <v-card-title class="grey--text text-subtitle-1">
      Fitofarmaci in Carico
      <v-spacer />
      <v-dialog v-model="dialog_nuovo" width="800">
        <template v-slot:activator="{ on }">
          <v-btn outlined color="green" v-on="on" class="ml-8"
            ><v-icon class="mr-2">mdi-medication</v-icon>Aggiungi
            Fitofarmaco</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="grey--text text-subtitle-1">
            Nuovo Carico
            <v-spacer />
            <v-dialog width="600" v-model="dialog_aggiorna">
              <template v-slot:activator="{ on }">
                <v-btn color="indigo" outlined v-on="on" class="ml-4"
                  ><v-icon>mdi-update</v-icon> Aggiorna</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  Aggiornamento Fitofarmaci in corso..
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12"> Processati: {{fito_progress.processati}} </v-col>
                    <v-col cols="12"> Aggiunti: {{fito_progress.aggiunti}} </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-progress-linear color="indigo" height="25" :value="fito_progress.percent"
                        ><span class="white--text">{{(fito_progress.percent).toFixed(2)}}%</span>
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    outlined
                    color="orange"
                    :disabled="!scan_enable"
                    @click="fito_update()"
                    >Scansiona</v-btn
                  >
                  <v-btn text color="grey" @click="dialog_aggiorna = false">chiudi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-snackbar v-model="avviso_exist" top shaped color="red">
            <v-icon class="mr-2">mdi-alert</v-icon>
            Attenzione. Il fitofarmaco selezionato è già presente
          </v-snackbar>
          <v-card-text>
            <v-autocomplete
              v-model="id_prodotto_sel"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              chips
              clearable
              hide-details
              hide-selected
              item-text="prodotto"
              item-value="_id.$oid"
              label="Ricerca Prodotto..."
              outlined
            ></v-autocomplete>
            <template v-if="fitof_select">
              <v-row class="mt-4">
                <v-col>
                  <b># Registrazione: </b>
                  {{ fitof_select.numero_registrazione }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Nome: </b>
                  {{ fitof_select.prodotto }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Titolare Registrazione: </b>
                  {{ fitof_select.impresa }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Sostanze: </b>
                  {{ fitof_select.sostanze_attive }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Attività: </b>
                  {{ fitof_select.attivita }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Formulazione: </b>
                  {{ fitof_select.descrizione_formulazione }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Indicazioni di Pericolo: </b>
                  {{ fitof_select.indicazioni_di_pericolo }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Data Registrazione: </b>
                  {{ fitof_select.data_registrazione }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Stato Amministrativo: </b>
                  {{ fitof_select.stato_amministrativo }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Scadenza Autorizzazione: </b>
                  {{ fitof_select.scadenza_autorizzazione }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Decorrenza Revoca: </b>
                  {{ fitof_select.data_decorrenza_revoca }}
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="green"
              :disabled="!fitof_select"
              :loading="loading_invio"
              @click="aggiungi_prodotto_fito"
              >Aggiungi</v-btn
            >
            <v-btn text color="grey" @click="dialog_nuovo = false"
              >chiudi</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2"> Nome Prodotto </v-col>
        <v-col cols="2"> Sostanza Attiva </v-col>
        <v-col cols="2"> Stato Amministrativo </v-col>
        <v-col cols="2"> Scadenza Autorizzazione </v-col>
        <v-col cols="2"> In Magazzino </v-col>
      </v-row>
      <v-divider class="my-2" />
      <FitoElement
        v-for="fito in fitofarmaci_magazzino"
        :key="fito.id"
        :fito="fito"
      />
    </v-card-text>
    <!-- </v-card> -->
  </v-row>
</template>

<script>
import FitoElement from "./fitofarmaco_element.vue";
import fito_tab_mixin from "../mixin/fitofarmaco_tab_mixin";
export default {
  components: {
    FitoElement,
  },
  mixins: [fito_tab_mixin],
  data() {
    return {
      scan_enable: true,
      dialog_aggiorna: false
    };
  },
  computed:{
    fito_progress(){
      return this.$store.getters.get_fito_update
    }
  },
  methods: {
    fito_update() {
      this.scan_enable = false;
      this.$store.dispatch("fito_update");
    },

  },
  // data() {
  //   return {
  //     dialog_nuovo: false,
  //     id_prodotto_sel: null,
  //     tab_loading: false,
  //     isLoading: false,
  //     loading_invio: false,
  //     items: [],
  //     search: null,
  //   };
  // },
  // watch: {
  //   search(val) {
  //     console.log(val);
  //     // Items have already been loaded
  //     if (val.length < 3) {
  //       return;
  //     }
  //     if (this.isLoading) {
  //       return;
  //     }

  //     this.isLoading = true;

  //     // Lazily load input items
  //     fetch(process.env.VUE_APP_API_ROOT + "/admin/fitofarmaco/" + this.search)
  //       .then((res) => res.clone().json())
  //       .then((res) => {
  //         this.items = res;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => (this.isLoading = false));
  //   },
  // },
  // computed: {
  //   fitof_select() {
  //     return this.items.find((fito) => fito._id.$oid == this.id_prodotto_sel);
  //   },
  //   fitofarmaci_magazzino() {
  //     return this.$store.getters.get_fitofarmaci;
  //   },
  // },
  // methods: {
  //   aggiungi_prodotto_fito() {
  //     this.loading_invio = true;
  //     this.$store
  //       .dispatch("add_prodotto_fito", this.id_prodotto_sel)
  //       .then((res) => {
  //         this.loading_invio = false;
  //         if(res == 'exist'){

  //         }else{

  //         }
  //         this.dialog_nuovo = false;
  //       });
  //   },
  // },
};
</script>