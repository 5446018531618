<template>
  <v-row no-gutters>
    <v-card-title class="grey--text text-subtitle-1"
      >Avversità Registrate
      <v-spacer />
      <v-dialog v-model="dialog_nuovo" width="800">
        <template v-slot:activator="{ on }">
          <v-btn outlined color="green" v-on="on" class="ml-8"
            ><v-icon class="mr-2">mdi-bug</v-icon>Aggiungi Avversità</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="grey--text text-subtitle-1">
            Nuova Avversità
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Nome Comune"
                  v-model="nome_avv"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="Nome Scientifico"
                  v-model="nome_sc_avv"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="tipologia"
                  v-model="cod_avv"
                  item-text="text"
                  item-value="cod"
                  hide-details
                  outlined
                  label="Tipologia"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey" text @click="dialog_nuovo = false"
              >chiudi</v-btn
            >
            <v-btn
              color="green"
              outlined
              :loading="loading_invia"
              :disabled="!nome_avv || cod_avv == null"
              @click="invia_nuovo()"
              >Invia</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2"> Nome Avversità </v-col>
        <v-col cols="2"> Nome Scientifico </v-col>
        <v-col cols="2"> Tipologia </v-col>
      </v-row>
      <v-divider class="my-2" />
      <AvversitaEl v-for="avv in avversita" :key="avv.id" :avv="avv" />
    </v-card-text>
  </v-row>
</template>

<script>
import AvversitaEl from "./avversita_element.vue";
export default {
  components: {
    AvversitaEl,
  },
  data() {
    return {
      dialog_nuovo: false,
      tipologia: [
        { cod: 0, text: "Fisiopatia" },
        { cod: 1, text: "Insetto" },
        { cod: 2, text: "Fungo" },
        { cod: 3, text: "Infestanti" },
      ],
      loading_invia: null,
      nome_avv: null,
      nome_sc_avv: null,
      cod_avv: 0,
    };
  },
  computed: {
    avversita() {
      return this.$store.getters.get_avversita;
    },
  },
  methods: {
    invia_nuovo() {
      this.loading_invia = true;
      this.$store
        .dispatch("add_avversit_fito", {
          nome: this.nome_avv,
          tipo: this.cod_avv,
          nome_sc: this.nome_sc_avv,
        })
        .then(() => {
          this.loading_invia = false;
        });
    },
  },
};
</script>