import moment from "moment";
export default {
  props: {
    fito: Object,
  },
  data() {
    return {
      dialog_carico: false,
      dialog_scarico: false,
      date: moment().format("YYYY-MM-DD"),
      dialog_data: false,
      dialog_movimenti: false,
      id_fornitore: null,
      qta_carico: null,
      qta_scarico: null,
      loading_invio: false,
      anno_rif: moment().year(),
      motivo_scarico: null,
      menu_delete: false,
      loading_elimina: false,
      no_delete: false,
    };
  },
  computed: {
    fornitori() {
      return this.$store.getters.get_fornitori.filter(
        (forn) => forn.tipologia == 2
      );
    },
    date_ts() {
      return moment(this.date).format("X");
    },
    anni() {
      let adesso = moment().format("YYYY");
      return [adesso, adesso - 1, adesso - 2];
    },
    movimenti_carico() {
      return this.fito.movimenti_carico.filter(
        (mov) => moment.unix(mov.ts).year() == this.anno_rif
      );
    },
    movimenti_scarico() {
      return this.fito.movimenti_scarico.filter(
        (mov) => moment.unix(mov.ts).year() == this.anno_rif
      );
    },
    in_magazzino() {
      return (
        this.fito.movimenti_carico
          .map((mov) => mov.qta_carico)
          .reduce((tot, num) => tot + num, 0) -
        this.fito.movimenti_scarico
          .map((mov) => mov.qta_scarico)
          .reduce((tot, num) => tot + num, 0)
      );
    },
    array_combinato() {
      let carico = this.movimenti_carico.map((mov) => {
        return {
          ts: mov.ts,
          type: "carico",
          value: mov,
        };
      });
      let scarico = this.movimenti_scarico.map((mov) => {
        return {
          ts: mov.ts,
          type: "scarico",
          value: mov,
        };
      });
      return [...carico, ...scarico].sort((a, b) => b.ts - a.ts);
    },
  },
  methods: {
    aggiungi_movimemnto() {
      this.loading_invio = true;
      this.$store
        .dispatch("add_movimento_prodotto_fito", {
          id_prodotto: this.fito.id_fitofarmaco,
          id_fornitore: this.id_fornitore,
          ts: this.date_ts,
          qta: this.qta_carico,
        })
        .then(() => {
          this.loading_invio = false;
          this.dialog_carico = false;
        });
    },
    aggiungi_movimemnto_scarico() {
      this.loading_invio = true;
      this.$store
        .dispatch("add_movimento_scarico_prodotto_fito", {
          id_prodotto: this.fito.id_fitofarmaco,
          ts: this.date_ts,
          qta: this.qta_scarico,
          motivo: this.motivo_scarico,
        })
        .then(() => {
          this.loading_invio = false;
          this.dialog_scarico = false;
        });
    },
    elimina_fitofarmaco() {
      this.loading_elimina = true;
      this.$store
        .dispatch("delete_prodotto_fito", {
          id_prodotto: this.fito.id_fitofarmaco,
        })
        .then((res) => {
          this.loading_elimina = false;
          this.menu_delete = false;
          console.log(res);
          if (res.res == "forbidden") {
            this.no_delete = true;
          }
        });
    },
  },
};
