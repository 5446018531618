<template>
  <v-dialog fullscreen v-model="dialog">
    <v-card>
      <v-card-title class="grey--text text-subtitle-1"
        >Nuovo Evento</v-card-title
      >
      <v-card-text>
        <v-dialog
          ref="dialog"
          v-model="dialog_data"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Data Intervento"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable locale="IT">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialog_data = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-select
          class="mt-4"
          v-model="fitofarmaci_utiliz"
          prepend-inner-icon="mdi-medication-outline"
          label="Prodotto Utilizzato"
          :items="fitofarmaci"
          item-text="dettagli.prodotto"
          item-value="id_fitofarmaco"
          outlined
          hide-details
          chips
          multiple
        />
        <v-row v-if="fitofarmaci_utiliz" class="mt-4">
          <v-col
            cols="6"
            v-for="(id_fito, index) in fitofarmaci_utiliz"
            :key="id_fito"
          >
            <v-text-field
              v-model="qta_fitofarmaci[index]"
              outlined
              :label="fitofarmaco(id_fito).dettagli.prodotto"
              suffix="Kg"
              hide-details
              :error="
                qta_fitofarmaci[index] ? isNaN(qta_fitofarmaci[index]) : false
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="lista_avversita"
              prepend-inner-icon="mdi-bug"
              label="Avversità"
              :items="avversita"
              item-text="nome"
              item-value="id"
              outlined
              hide-details
              chips
              multiple
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              prepend-inner-icon="mdi-spa"
              outlined
              :items="lista_fasi_fenologiche"
              label="Fase Fenologica"
              v-model="fase_fenologica"
              item-value="cod"
              item-text="text"
              chips
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              prepend-inner-icon="mdi-greenhouse"
              label="Zona Intervento"
              :items="zone"
              v-model="id_zone"
              item-text="nome"
              item-value="id"
              outlined
              chips
              multiple
              hide-details
            />
          </v-col>
        </v-row>
        <v-row class="">
          <v-col>
            <v-tabs v-model="tab" show-arrows>
              <v-tab v-for="(id_zona, index) in id_zone" :key="id_zona"
                >{{ zona(id_zona).nome }}
                <v-checkbox
                  class="ml-2 mt-5"
                  color="green"
                  v-model="check_all[index]"
                  @click="fill_all(index)"
                ></v-checkbox>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12">
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(coltura_zona, index) in colture_zone"
                :key="coltura_zona.id"
              >
                <v-card tile class="scroll">
                  <v-row no-gutters>
                    <v-col>
                      <v-list-item
                        v-for="coltura in coltura_zona.colture"
                        :key="coltura.coltura.id"
                        @click="
                          toggle_item({
                            index: index,
                            lotto: coltura.lotto,
                            id: coltura.coltura.id_prodotto,
                          })
                        "
                      >
                        <v-list-item-avatar>
                          <v-img
                            :src="avatar_img(coltura.coltura.id_prodotto)"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ nome_coltura(coltura.coltura.id_prodotto)[0] }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ nome_coltura(coltura.coltura.id_prodotto)[1] }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="lotto(coltura.lotto)">
                            {{ lotto(coltura.lotto).codice }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            readonly
                            :input-value="
                              item_selected({
                                index: index,
                                lotto: coltura.lotto,
                                id: coltura.coltura.id_prodotto,
                              })
                            "
                            hide-details
                          />
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              v-model="note"
              label="Note"
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
        <v-btn
          outlined
          color="green"
          @click="invia_evento()"
          :disabled="
            !lista_avversita.length ||
            !fitofarmaci_utiliz.length ||
            !id_zone.length ||
            qta_fitofarmaci.length != fitofarmaci_utiliz.length ||
            !num_box_ok
          "
          :loading="loading_nuovo"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          Salva Evento</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import nuovo_evento_mixin from "../mixin/nuovo_evento_mixin";
export default {
  mixins: [nuovo_evento_mixin],

  watch: {
    $route(to, from) {
      if (from.hash === "#nuovo-evento" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#nuovo-evento");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
</script>