<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Nuovo Movimento per {{ fito.dettagli.prodotto }}
      </v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab><v-icon class="mr-2">mdi-swap-horizontal</v-icon>Mov.</v-tab>
          <v-tab><v-icon class="mr-2">mdi-arrow-up</v-icon>Carica</v-tab>
          <v-tab><v-icon class="mr-2">mdi-arrow-down</v-icon>Scarica</v-tab>

          <v-tab-item class="mt-4">
            <template v-for="(mov, index) in array_combinato">
              <MovimentoCarico
                v-if="mov.type == 'carico'"
                :movimento_carico="mov.value"
                :id_prodotto="fito.id_fitofarmaco"
                :key="index"
              />
              <MovimentoScarico
                v-if="mov.type == 'scarico'"
                :movimento_scarico="mov.value"
                :id_prodotto="fito.id_fitofarmaco"
                :key="index"
              />
            </template>
          </v-tab-item>
          <v-tab-item class="mt-4">
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="fornitori"
                  v-model="id_fornitore"
                  label="Fornitore"
                  item-value="id"
                  item-text="nome"
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-dialog
                  ref="dialog"
                  v-model="dialog_data"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Data di Carico"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable locale="IT">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog_data = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="qta_carico"
                  outlined
                  label="Quantità Caricata"
                  suffix="Kg/lt."
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  outlined
                  color="green"
                  @click="aggiungi_movimemnto()"
                  :disabled="!id_fornitore || !date || !qta_carico"
                  :loading="loading_invio"
                  >Carica</v-btn
                >
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="mt-4">
            <v-row>
              <v-col>
                <v-dialog
                  ref="dialog"
                  v-model="dialog_data"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Data di Scarico"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable locale="IT">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog_data = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="qta_scarico"
                  outlined
                  label="Quantità Scaricata"
                  suffix="Kg/lt."
                  type="number"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Motivo dello scarico"
                  v-model="motivo_scarico"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  outlined
                  color="green"
                  @click="aggiungi_movimemnto_scarico()"
                  :disabled="!motivo_scarico || !qta_scarico"
                  :loading="loading_invio"
                  >Scarica</v-btn
                >
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fito_element_mixin from "../mixin/fitofarmaco_element_mixin";
import MovimentoCarico from "../desktop/fitofarmaco_movimento_carico_element.vue";
import MovimentoScarico from "../desktop/fitofarmaco_movimento_scarico_element.vue";
export default {
  props: {
    fito: Object,
  },
  components: {
    MovimentoCarico,
    MovimentoScarico,
  },
  mixins: [fito_element_mixin],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-carico" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-carico");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
</script>