<template>
  <v-hover v-slot="{ hover }">
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="red">mdi-arrow-down</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ data_format }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <b> {{ movimento_scarico.qta_scarico }}</b> Kg/Lt.
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ movimento_scarico.motivo }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          color="red"
          v-if="hover"
          :loading="loading_elimina"
          @click="elimina_movimento()"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import moment from "moment";
export default {
  props: {
    movimento_scarico: Object,
    id_prodotto: String,
  },
  data() {
    return {};
  },
  computed: {
    data_format() {
      return moment.unix(this.movimento_scarico.ts).format("DD/MM/YYYY");
    },
  },
  methods: {
    elimina_movimento() {
      this.loading_elimina = true;
      this.$store.dispatch("delete_movimento_scarico_prodotto_fito", {
        id_prodotto: this.id_prodotto,
        id_scarico: this.movimento_scarico.id,
      });
    },
  },
};
</script>